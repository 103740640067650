.cert-container {
    /* width: 904px;
    height: 644px; */
    position: relative;
}

.cert-container .elements {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    transform: scale(1);
    mix-blend-mode: multiply;
}

.cert-container img {
    width: 100%;
}

.slider-1 {
    transform: scale(calc(var(--scale-width) * 60%));
    transform-origin: 0 0;
}

.slider-2 {
    transform: scale(calc(var(--scale-width) * 80%));
    transform-origin: 0 0;
}

.slider-3 {
    transform: scale(calc(var(--scale-width) * 100%));
    transform-origin: 0 0;
}

.slider-4 {
    transform: scale(calc(var(--scale-width) * 120%));
    transform-origin: 0 0;
}

.slider-5 {
    transform: scale(calc(var(--scale-width) * 140%));
    transform-origin: 0 0;
}

.slider-6 {
    transform: scale(calc(var(--scale-width) * 160%));
    transform-origin: 0 0;
}

.slider-7 {
    transform: scale(calc(var(--scale-width) * 180%));
    transform-origin: 0 0;
}

.recenter-certificate {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    width: 40px !important;
    height: 40px !important;
    border-radius: 10px;
    padding: 0px !important;
    opacity: 0.3;
    transition: 0.3s;
}

.recenter-certificate:hover {
    opacity: 1;
    transition: 0.3s;
}

@media screen and (max-width: 820px) {
    .slider-1 {
        transform-origin: 0 25%;
    }

    .slider-2 {
        transform-origin: 0 25%;
    }

    .slider-3 {
        transform-origin: 0 25%;
    }

    .slider-4 {
        transform-origin: 0 25%;
    }

    .slider-5 {
        transform-origin: 0 25%;
    }

    .slider-6 {
        transform-origin: 0 25%;
    }

    .slider-7 {
        transform-origin: 0 25%;
    }

    .pg-certficate_viewer {
        background-color: #4E5761 !important;
        padding: 5px !important;
        height: 100vh !important;
    }

    .pg-certficate_viewer .wrapper {
        background-color: #4E5761;
        gap: 5px;
    }

    .mob-actions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        background-color: #FFF;
        border-radius: 10px 10px 0px 0px;
        width: 100%;
        overflow-x: auto;
        padding: 10px 0px;
    }

    .mob-actions .nav-container {
        flex-direction: row !important;
        justify-content: flex-start;
        padding-top: 0px;
        gap: 10px;
        min-width: 100%;
        width: fit-content;
        padding: 10px;
    }

    .mob-actions .nav-container .nav {
        flex-direction: column;
        font-size: 10px;
        gap: 3px;
    }

    .mob-actions::-webkit-scrollbar {
        display: none;
    }
}
body {
    background-color: #F1F1F1;
    background-image: url(../resources/bg-line.svg);
    background-size: cover;
    background-position: -30vw -30vh;
    /* background-repeat: no-repeat; */
}

.signin img {
    max-height: 80vh;
    max-width: 50vw;
}

.signin .wrapper {
    background-image: linear-gradient(20deg, #FCFCFC, #F1F1F1);
    padding: 30px 60px;
    width: 30vw;
    max-width: 400px;
}

.signin h1 {
    font-family: 'Mitr', sans-serif;
    font-weight: 400;
    font-size: 24px;
}

.signin .wrapper .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
}

.signin .wrapper .container .userimage {
    height: 90px;
    display: block;
    margin: auto;
}

.signin label {
    font-family: 'Mitr', sans-serif;
    font-weight: 200;
    font-size: 14px;
    display: block;
    margin-bottom: 5px;
}

.signin p {
    font-size: 14px;
    text-align: center;
    width: 100%;
}

.action-btn {
    text-align: center;
    width: 100%;
    color: #2977C9;
}

.signin p .secondary {
    color: #2977C9;
    font-weight: 600;
}

.signin p a {
    text-decoration: none;
    color: #000;
    font-size: 14px;
}

.signin p.success {
    background-color: transparent;
    color: #2BCEAD;
    font-weight: 600;
}

.redirect-status {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    background-color: #2BCEAD;
    color: #FFF;
    text-align: center;
    border-radius: 0px;
}

.redirect-status.failed {
    background-color: #DF7184;
}

#googleButton {
    width: 100%;
}

#googleButton button {
    width: 100%;
    border-radius: 10px !important;
    padding: 3px !important;
    justify-content: center;
    color: #000 !important;
    opacity: 1 !important;
}

.password-warns {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 3px;
    flex-direction: column;
}

.password-warns p {
    font-size: 12px;
    font-weight: 300;
    color: #888;
    vertical-align: middle;
    text-align: left;
}

.password-warns .fa-circle-xmark {
    color: #DF7184;
}

.password-warns .fa-circle-check {
    color: #2BCEAD;
}

.kicked {
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
}

.kicked h1 {
    text-align: center;
    color: #DF7184;
}

.kicked h1:last-child {
    color: #8b6cc0;
    font-size: 18px;
}

.kicked p {
    color: #000;
}

.kicked img {
    margin: 30px 0px;
    height: 300px;
    width: auto;
    object-fit: contain;
    background-color: #000;
    border-radius: 50%;
}

@media screen and (max-width: 1200px) {
    .signin .landing-img {
        display: none;
    }

    .signin .wrapper {
        width: 400px;
    }
}

@media screen and (max-width: 820px) {
    .signin {
        padding: 20px;
        align-content: flex-start;
        justify-content: center;
    }

    .signin .wrapper {
        padding: 30px 20px;
    }

    .kicked img {
        height: auto;
    }
}
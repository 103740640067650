.element {
  width: fit-content;
  flex-grow: 1;
  max-height: 100%;
}

.min-element {
  max-width: calc(100% - 420px);
}

.max-element {
  max-width: calc(100% - 120px);
}

.full-element {
  max-width: 100%;
  min-height: 700px;
}

.full-element .marketplace {
  height: 100% !important;
  max-height: 640px !important;
}

.no-scroll {
  overflow-y: hidden !important;
  height: 100%;
}

.element .container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.element .container .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  gap: 20px;
}

.btn-issue {
  /* background-color: #2BCEAD; */
  background-image: linear-gradient(60deg, #3d3393 0%, #2b76b9 37%, #2cacd1 65%, #35eb93 100%);
  background-size: 200% auto;
  animation: gradientAnimation 5s linear infinite;
  color: #FFF;
  width: fit-content;
  box-shadow: 4px 1px 13px 0px #00000021;
  border: none;
  transition: 0.3s;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

.btn-issue:hover {
  box-shadow: none;
  box-shadow: none;
  transition: 0.3s;
}

.element .container .header h1 {
  font-family: 'Mitr', sans-serif;
  font-size: 24px;
  color: #2977c9;
  font-weight: 400;
  line-height: 20px;
}

.element .container .header h1 label {
  font-family: 'Mitr', sans-serif;
  font-size: 14px;
  color: #2977c9;
  font-weight: 300;
  display: block;
  color: #8E8E8E;
  text-transform: capitalize;
}

.element .container .header .toggle {
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-self: center;
  justify-content: center;
  background-color: transparent;
  font-family: "Open Sans", sans-serif;
}

.element .container .header .toggle button {
  padding: 5px 15px;
  border-radius: 5px;
  border: none;
  background-color: #FFF;
  color: #2977c9;
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  transition: 0.3s;
}

.element .container .header .toggle button:hover {
  background-color: #157EFB21;
  transition: 0.3s;
}

.element .container .header .toggle button.active {
  background-color: #2977c9;
  color: #FFF;
}

.element .tiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  max-height: calc(100vh - 210px);
  overflow-y: scroll;
  position: relative;
  color: rgba(0, 0, 0, 0);
  transition: color .3s ease;
  padding-bottom: 160px;
  /* for UI in middle issue resolving */
  height: 100vh;
  align-content: flex-start;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 26px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}

.element .tiles:hover {
  color: rgba(0, 0, 0, 0.3);
}

.element .fadeout {
  position: sticky;
  bottom: -30px;
  right: 0;
  width: 100%;
  z-index: 3;
  background-image: linear-gradient(to bottom, transparent, #F1F1F1);
  height: 120px;
}

.element .tiles .tile {
  padding: 15px;
  border-radius: 10px;
  background-color: #FFF;
  width: 400px;
  height: 300px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  transition: 0.3s;
  z-index: 3;
}

.tile-wrapper:hover .tile {
  height: 240px;
  transition: 0.3s;
}

.tile-wrapper:hover .action {
  height: 50px;
  transition: 0.3s;
}

.tile-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 300px;
  position: relative;
}

.tile-wrapper.pronetin .tile,
.tile-wrapper.pronetin .action {
  background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
}

.tile-wrapper.pronetin .tile h1 {
  color: #157EFB;
}

.tile-wrapper.pronetin .tile .trial {
  color: #3d3393;
}

.tile-wrapper.pronetin .tile h1 label {
  color: #FFF;
  font-weight: 600;
}

.tile-wrapper.pronetin .tile p {
  color: #FFF;
  font-weight: 600;
}

.tile-wrapper.pronetin .tile .view-option .view {
  background-color: #2BCEAD;
  color: #FFF;
}

.tile-wrapper.pronetin .tile .organisation {
  background-color: #157EFB;
  color: #FFF;
}

.tile-wrapper .action {
  background-color: #FFF;
  color: #000;
  height: 70px;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  transition: 0.3s;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tile-wrapper .uploading-status {
  padding-right: 20px;
  color: #002D63;
  width: fit-content;
}

.tile-wrapper .action .icon-btn {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.tile-wrapper .action .icon-btn button {
  background-color: #F1F1F1;
  color: #2977c9;
}

.tile-wrapper .action .icon-btn button i {
  font-size: 16px;
}

.tile-wrapper .action .icon-btn button:hover {
  color: #FFF;
}

.tile-wrapper .action button,
.tile-wrapper .action a {
  font-family: 'Mitr', sans-serif;
  font-size: 18px;
  border-radius: 10px;
  border: none;
  font-weight: 300;
  height: 40px;
  padding: 0px 20px;
  width: fit-content;
  background-color: #2977c9;
  color: #FFF;
  transition: 0.3s;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.element .tiles.marketplace {
  flex-wrap: nowrap;
  flex-direction: row;
}

.doc-alt-marketplace {
  height: fit-content !important;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 0px !important;
}

.doc-alt-marketplace .mob-actions {
  background-color: #D1EDFF;
}

.marketplace .nav-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  padding-top: 10px;
  overflow-y: auto;
}

.marketplace .document-info {
  padding-bottom: 10px;
}

.marketplace .nav-container .request {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  background-color: #FFF;
  border-radius: 10px;
  padding: 10px;
}

.marketplace .nav-container hr {
  width: 100%;
  border: none;
  background-color: #157efb6b;
  height: 1px;
}

.marketplace .nav-container .request .btn {
  background-color: #ffcb74;
  padding: 10px 20px;
  color: #000;
  font-size: 14px;
  border-radius: 5px;
  transition: 0.3s;
  text-decoration: none;
  display: block;
}

.marketplace .nav-container .request .btn:hover {
  background-color: #ff9800;
  transition: 0.3s;
}

.marketplace .nav-container .info-container {
  width: 100%;
}

.marketplace .nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: #000;
  font-size: 16px;
}

.marketplace .nav.active {
  background-image: linear-gradient(to right, #D1EDFF 10%, transparent 90%);
  border-radius: 10px;
}

.marketplace .nav.active i {
  background-color: #2977c9;
  color: #FFF;
}

.marketplace .nav.active:hover i {
  background-color: #2977c9;
  color: #FFF;
}

.marketplace .nav.green i {
  color: #2BCEAD;
  border: 1px solid #2BCEAD;
}

.marketplace .nav.yellow i {
  color: #ff9800;
  border: 1px solid #ff9800;
}

.marketplace .nav:hover i {
  background-color: #FFF;
  transition: 0.3s;
}

.marketplace .nav.warn i {
  color: #D5415A;
  border: 1px solid #D5415A;
  transition: 0.3s;
}

.marketplace .nav i {
  color: #2977c9;
  background-color: #F1F1F1;
  border-radius: 10px;
  border: 1px solid #2977c9;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  transition: 0.3s;
}

.marketplace .image-container {
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.marketplace .image-container img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.tile-wrapper .action button:hover {
  background-color: #157EFB;
  transition: 0.3s;
}

.tile .view-option {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.tile .view-option.public {
  color: #2BCEAD;
  font-weight: 600;
}

.tile .view-option.public .view {
  border: 1px solid #2BCEAD;
  padding: 5px 10px;
  border-radius: 10px;
}

.tile .view-option.private {
  color: #DF7184;
  font-weight: 600;
}

.tile .view-option.private .view {
  border: 1px solid #DF7184;
  padding: 5px 10px;
  border-radius: 10px;
}

.tile h1 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  line-height: 21px;
  overflow: hidden;
  width: 100%;
  height: 40px;
  position: relative;
}

.tile h1 span {
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  font-weight: inherit;
  white-space: nowrap;
  overflow: auto;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  width: fit-content;
}

.tile:hover span.scrolling {
  animation-play-state: paused;
}

.tile h1 span.scrolling {
  animation: text-scroll 10s linear infinite;
}

@keyframes text-scroll {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.tile h1 label {
  font-size: 14px;
  font-weight: 400;
  color: #8E8E8E;
  display: block;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.tile p {
  font-size: 14px;
  font-weight: 400;
  color: #8E8E8E;
  display: block;
  width: 100%;
}

.tile .organisation {
  border: 1px solid #2977c9;
  background-color: #EFF7FF;
  color: #2977c9;
  padding: 5px 10px;
  border-radius: 10px;
  text-align: center;
  font-family: 'Mitr', sans-serif;
  font-size: 14px;
}

.tile .bottom {
  width: 100%;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.tile .bottom .trial {
  color: #DF7184;
  font-size: 14px;
}

.tile .bottom .views {
  color: #8E8E8E;
  font-size: 14px;
  font-weight: 600;
}

.template-env {
  width: 100%;
  /* width: 940px; */
  min-height: 100px;
  background-color: #FFF;
  aspect-ratio: 4/3;
  border-radius: 10px;
  position: relative;
}

.issue-page {
  justify-content: space-between;
}

.certificate-template {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.hide {
  display: block;
  height: 0px;
  overflow: hidden;
  width: fit-content;
}

.box {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  cursor: move;
  color: black;
  background-color: transparent;
  border-radius: 5px;
  border: none;
  margin: auto;
  user-select: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;
  width: auto;
}

.box.pinned {
  box-shadow: none;
}

.box.pinned input::-webkit-scrollbar,
.box.pinned textarea::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.box textarea,
.box input {
  border: 2px solid transparent;
  color: #000;
  background-color: transparent;
  border-radius: 5px;
  text-align: left;
  padding: 5px;
  width: 300px;
  max-width: 100%;
  overflow: hidden;
  min-height: 30px;
  resize: none;
  cursor: move;
  caret-color: transparent;
}

.box input[type='date'] {
  width: fit-content;
}

.box input:hover,
.box textarea:hover {
  transition: none;
  border: 2px solid #818181;
}

.box.selectFocus input,
.box.selectFocus textarea {
  transition: 0.3s;
  animation-name: selectFocus;
  animation-duration: 1s;
  animation-direction: normal;
}

@keyframes selectFocus {
  0% {
    background-color: transparent;
  }

  50% {
    background-color: #157EFB21;
  }

  100% {
    background-color: transparent;
  }
}

.box.pinned textarea,
.box.pinned input {
  border: 2px dashed #157EFB;
  resize: both;
  cursor: text;
  caret-color: #000;
  transition: none;
}

.box.pinned .content-controls input {
  border: 2px solid #157EFB;
  border-radius: 10px;
}

.box .wrap {
  position: relative;
  display: inline-block;
}

.box .wrap.no-pull-tab:after {
  display: none !important;
}

.box.pinned .wrap:after {
  content: "";
  border: 10px solid #157EFB;
  z-index: 1;
  position: absolute;
  right: -7px;
  bottom: -2px;
  pointer-events: none;
  display: block;
}

.box .pull-tab {
  display: none;
}

.box.pinned .pull-tab {
  height: 0px;
  width: 0px;
  border: 8px solid #FFF;
  position: absolute;
  bottom: -0px;
  right: -5px;
  pointer-events: none;
  z-index: 2;
  display: block;
}

.box .vLine .shape,
.box .hLine .shape {
  background-color: #8E8E8E;
}

.box .square .shape {
  background-color: transparent;
  border: 2px solid #8E8E8E;
}

.box .circle .shape {
  background-color: transparent;
  border: 2px solid #8E8E8E;
  border-radius: 50%;
}

.box .shape {
  width: 100%;
  height: 100%;
  min-width: 7px;
  min-height: 7px;
}

.box .imagearea {
  box-shadow: 0px 0px 10px transparent;
  transition: none;
  overflow: hidden;
  transition: 0.3s;
}

.box .imagearea-no-hover {
  box-shadow: none;
  transition: none;
  overflow: hidden;
}

.box .imagearea img {
  width: 100%;
  height: 100%;
  object-fit: fill !important;
}

.box .imagearea:hover {
  box-shadow: 0px 0px 10px #2977c939;
  transition: 0.3s;
}

.box.pinned .imagearea {
  box-shadow: 0px 0px 0px 2px #157EFB;
  transition: none;
  cursor: not-allowed;
}

.box .sign-container {
  height: fit-content;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #818181;
  text-align: center;
  background-color: transparent;
}

.box .sign-container img {
  mix-blend-mode: multiply;
  object-fit: contain !important;
  max-width: 140px;
}

.box .sign-container h1 {
  font-weight: 600;
}

.box .sign-container h2 {
  font-weight: 400 !important;
  font-size: 14px !important;
  justify-content: center;
  color: #818181;
  white-space: break-spaces;
}

.box table {
  border: 2px solid #DDDDDD;
  border-collapse: collapse;
  width: 100%;
  height: 100%;
  table-layout: fixed;
}

.box table:hover {
  border: 2px solid #818181;
}

.box td,
.box th {
  border: 1px solid #DDDDDD;
  padding: 1px;
  padding: 3px;
  font-size: inherit !important;
  height: 30px;
}

.box table tbody,
.box table thead,
.box tr,
.box td {
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  color: inherit !important;
  font-style: inherit !important;
  text-decoration: inherit !important;
  text-align: inherit !important;
  line-height: inherit !important;
}

.table-wrapper {
  width: 300px;
  min-width: 125px;
  overflow: auto;
}

.table-wrapper::-webkit-scrollbar {
  width: 0;
  display: none;
}

.box table i {
  padding: 5px;
}

.box.pinned table {
  cursor: default;
}

.box table td:last-child {
  cursor: default;
}

.box table i.fa-circle-xmark {
  color: #DF7184;
  background-color: transparent;
  border-radius: 5px;
  transition: 0.3s;
}

.box table i.fa-circle-xmark:hover {
  color: #F1F1F1;
  background-color: #DF7184;
  transition: 0.3s;
}

.box tr:nth-child(even) {
  background-color: #00000011;
}

.addRow-btn {
  background-color: #2BCEAD;
  color: #FFF;
  font-size: 14px;
  font-weight: 400;
  padding: 3px 15px !important;
  height: auto;
  border-radius: 5px;
  display: block;
  transition: 0.3s;
  gap: 10px;
  height: auto !important;
}

.addRow-btn:hover {
  background-color: #21937B;
  transition: 0.3s;
}

.field-label {
  display: block;
  color: #8E8E8E;
  font-size: 12px;
  font-weight: 500;
  padding-left: 7px;
}

.td-delete {
  min-width: none !important;
}

.td-delete i {
  display: block;
  margin: auto;
  width: fit-content;
}

.inherit {
  width: auto;
  min-width: 60px;
  outline: none;
  border: 2px solid #DDDDDD !important;
}

.inherit:hover {
  border: 2px solid #DDDDDD !important;
}

.pinned .inherit {
  border: 2px dashed #157EFB !important;
}

.pinned .inherit:hover {
  border: 2px dashed #157EFB !important;
}

.variable-controls {
  position: absolute;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  background-color: transparent;
  padding: 0px;
  border-radius: 10px;
  top: -30px;
  right: 0px;
  vertical-align: middle;
}

.variable-controls button {
  padding: 0px 10px !important;
  height: 25px !important;
  width: fit-content;
  font-size: 14px;
  font-weight: 300;
  border-radius: 5px;
}

.variable-controls .delete {
  background-color: #F1F1F1;
  color: #D5415A;
}

.variable-controls .variable {
  background-color: #2BCEAD;
  color: #FFF;
}

.variable-controls .readonly {
  background-color: #D5415A;
  color: #FFF;
}


.content-controls {
  position: absolute;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  background-color: #FFF;
  padding: 0px;
  border-radius: 10px;
  bottom: -25px;
  left: 0px;
}

.content-controls .container {
  position: relative;
}

.content-controls .container button {
  padding: 5px 10px !important;
  height: 35px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  background-color: #2977c9;
  color: #FFF;
  position: absolute;
  top: 0;
  right: 0;
}

.content-controls .container input {
  padding-right: 35px;
  width: 150px;
}

.content-controls button {
  padding: 5px 10px !important;
  height: 25px !important;
  border-radius: 5px;
  width: fit-content;
  font-size: 14px;
  font-weight: 300;
  background-color: #D1EDFF;
  color: #157EFB;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: 1px solid #D1EDFF;
}

.content-controls button:hover {
  border: 1px solid #157EFB;
}

.content-controls input {
  background-color: #F6F6F6;
  width: fit-content;
  resize: both;
  padding: 5px 10px;
  font-size: 14px;
  height: 35px;
  border: none;
}

.controls {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  background-color: #F1F1F1;
  padding: 5px;
  border-radius: 10px;
}

.controls select,
.controls input {
  background-color: #FFF;
  width: fit-content;
  resize: both;
  padding: 5px 10px;
  font-size: 14px;
  height: 35px;
  border: none;
}

.controls select:hover,
.controls input:hover {
  border: none;
}

.controls button {
  background-color: #FFF;
  color: #000;
  padding: 4px 10px;
  font-size: 17px;
}

.controls button.bold {
  font-weight: 500;
  width: 35px;
  height: 35px;
}

.controls button.italic {
  width: 35px;
  height: 35px;
  font-weight: 400;
  font-style: italic;
  font-family: 'Times New Roman', Times, serif;
}

.controls button.active {
  background-color: #2977c9;
  color: #FFF;
}

.controls .container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.controls .container h1 {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
}

::placeholder {
  font-weight: 600;
  color: #000;
}

.close-btn {
  position: absolute;
  top: -10px;
  right: -15px;
  border-radius: 50%;
  background-color: #FFF;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  z-index: 100;
}

.close-btn i {
  color: #DF7184;
  font-size: 20px;
}

.pin-btn {
  position: absolute;
  top: -10px;
  left: -15px;
  border-radius: 50%;
  background-color: #8E8E8E;
  color: #FFF;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.pin-btn.active {
  background-color: #2BCEAD;
}

.grid-btn {
  padding: 3px 20px;
  background-color: #8E8E8E;
  color: #FFF;
  width: fit-content;
  font-size: 14px;
}

.pin-btn i {
  color: inherit;
  font-size: 12px;
}

.palette {
  height: 35px;
  width: 35px;
  border: 2px solid #2977c9;
  transition: 0.3s;
  color: #000;
  padding: 0px !important;
  font-weight: 500;
}

.color-picker-modal {
  position: absolute;
  z-index: 99;
  top: -50px;
  right: 81px;
  transform: translate(12%, 36%);
  border-radius: 10px;
  cursor: pointer;
}

.color-picker-modal .color-picker-component {
  position: relative;
}

.color-picker-modal .color-picker-component .dropper {
  position: absolute;
  bottom: -33px;
  right: 0px;
  background-color: #FFF;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border: 2px solid #002D63;
}

.cursor-crosshair {
  cursor: crosshair;
}

.sketch-picker {
  box-shadow: -6px 6px 20px 0px #00000021 !important;
  border-radius: 10px !important;
}

.sketch-picker input {
  height: auto !important;
  border-radius: 2px;
  width: 100% !important;
}

.sketch-picker label {
  transition: none !important;
  letter-spacing: normal !important;
}

/* ---------- Profile Editor ----------- */

.element .settings {
  height: 100vh;
  border-radius: 10px;
  background-color: #FFF;
  padding: 20px;
}

.element .settings::-webkit-scrollbar {
  width: 0px;
}

.sub-nav {
  width: 310px;
  height: 100%;
  background-color: #F9F9F9;
  border-radius: 10px;
  position: relative;
}

.settings .wrapper,
.marketplace .wrapper {
  padding: 0px;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  overflow-y: scroll;
  padding: 0px 10px;
}

.marketplace .wrapper::-webkit-scrollbar {
  display: none;
  width: 0px;
}

.row-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}

.row-container .profile-icon-edit {
  width: 120px;
  height: 120px;
  border: 2px solid #2977c9;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.settings input.gray {
  background-color: #F1F1F1;
  border: 1px solid #F1F1F1;
  width: 400px;
}

.settings button,
.settings .button {
  width: fit-content;
  padding: 10px 30px;
  font-family: 'Mitr', sans-serif;
  font-size: 18px;
  border-radius: 10px;
  background-color: #2977C9;
  border: none;
  color: #FFF;
  font-weight: 300;
  text-decoration: none;
}

.settings input,
.settings select {
  border: 1px solid #2977c9;
  color: #000;
  width: 300px;
  max-width: 100%;
}

.new-change {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 12px !important;
  background-color: #002D63;
  color: #FFF !important;
  padding: 2px 5px;
  border-radius: 3px;
  font-weight: 300 !important;
}

.settings img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center;
  border-radius: 0px;
}

.settings-container .apply-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 0px;
  width: fit-content;
  background-color: transparent;
  border-radius: 0px;
  display: flex;
  justify-content: flex-end;
}

.settings-container .apply-btn button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: 0.3s;
}

.settings-container .apply-btn button p {
  display: none;
  color: #FFF;
}

.settings-container .apply-btn button:hover {
  width: 100px;
  border-radius: 10px;
  transition: 0.3s;
}

.settings-container .apply-btn button:hover p {
  display: block;
}

.settings-container .apply-btn button i {
  font-size: 21px;
}

.row-container .profile-icon-edit .edit-btn {
  position: absolute;
  bottom: 5px;
  right: 5px;
  height: 30px;
  width: 30px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}

.row-container .profile-icon-edit .edit-btn:hover {
  background-color: #D1EDFF;
  color: #157EFB;
  transition: 0.3s;
}

.check-validity-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;
}

.check-validity-container p {
  font-size: 14px !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  color: #002D63 !important;
}

.check-validity-container .green {
  color: #21937B;
}

.check-validity-container .red {
  color: #D5415A;
}

.column-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  color: #000;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.column-container label {
  font-size: 14px;
  color: #818181;
}

.column-container label span {
  color: #157EFB;
  font-weight: 600;
}

.row-container .column-container {
  width: fit-content;
}

.settings p {
  font-family: 'Mitr', sans-serif;
  color: #2977c9;
  font-size: 14px;
}

.settings p.info {
  color: #818181;
  font-family: "Open Sans", sans-serif;
}

.settings p.warn {
  color: #D5415A;
  font-family: "Open Sans", sans-serif;
}

.settings h1 {
  font-family: 'Mitr', sans-serif;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 18px;
}

.settings .danger-zone {
  color: #DF7184;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 100%;
  gap: 20px;
}

.settings .danger-zone p {
  color: #DF7184;
}

.danger-zone hr {
  width: 50%;
  border: none;
  background-color: #DF7184;
  height: 2px;
}

.settings .partition-zone p {
  color: #2BCEAD;
}

.partition-zone hr {
  width: 25%;
  border: none;
  background-color: #2BCEAD;
  height: 2px;
}

.red-btn {
  background-color: #DF7184 !important;
}

.red-btn:hover {
  background-color: #D5415A !important;
}

.settings .text {
  font-weight: 300;
  color: #8E8E8E;
  width: 50%;
}

.complete-profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  background-color: #FFF;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  color: #157EFB;
  width: calc(100% - 20px);
  padding: 20px;
  border-radius: 10px;
  margin: 10px;
  position: relative;
  flex-wrap: wrap;
}

.complete-profile h2 {
  font-size: 16px;
  font-weight: 600;
  color: #002D63;
}

.complete-profile p {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 21px;
  font-size: 14px;
}

.complete-profile p.pointer {
  padding-left: 10px;
}

.complete-profile .pointer i.red {
  color: #D5415A;
}

.complete-profile .pointer i.green {
  color: #21937B;
}

.complete-profile .circle-wrap {
  width: 150px;
  height: 150px;
  background: #e6e2e7;
  border-radius: 50%;
}

.complete-profile .circle-wrap .circle .mask,
.complete-profile .circle-wrap .circle .fill {
  width: 150px;
  height: 150px;
  position: absolute;
  border-radius: 50%;
}

.complete-profile .circle-wrap .circle .mask {
  clip: rect(0px, 150px, 150px, 75px);
}

.complete-profile .circle-wrap .circle .mask .fill {
  clip: rect(0px, 75px, 150px, 0px);
  background-color: #2BCEAD;
}

.complete-profile .circle-wrap .circle .mask.full,
.complete-profile .circle-wrap .circle .fill {
  animation: fill ease-in-out 1.5s;
  transform: rotate(var(--circle-percentage));
}

@keyframes fill {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(var(--circle-percentage));
  }
}

.complete-profile .circle-wrap .inside-circle {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background: #fff;
  line-height: 130px;
  text-align: center;
  margin-top: 10px;
  margin-left: 10px;
  position: absolute;
  z-index: 100;
  font-weight: 600;
  font-size: 2em;
  color: #157EFB;
}

/* -----------Profile Storage----------- */

.profile-progress {
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.wave {
  position: relative;
  width: 100%;
  height: 100%;
  background: #2977c9;
  border-radius: 10px;
}

.wave.red {
  background: #DF7184;
}

.wave:before,
.wave:after {
  content: '';
  position: absolute;
  height: 110%;
  aspect-ratio: 1;
  top: var(--storage-var);
  left: 50%;
  transform: translate(-50%, -75%);
  background: #000;
}

.wave:before {
  border-radius: 45%;
  background: transparent;
  animation: animate 20s linear infinite;
}

.wave:after {
  border-radius: 40%;
  background: #F1F1F1;
  animation: animate 10s linear infinite;
}

.sub-nav .profile-storage {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  color: #000;
  width: 100%;
}

.sub-nav button {
  border: 1px solid #FFF;
}

.sub-nav button:hover {
  border: 1px solid #FFF;
  transition: 0.3s;
}

.sub-nav .profile-storage label {
  font-size: 40px;
  font-weight: 600;
  text-transform: capitalize;
}

.sub-nav .profile-storage label b {
  font-size: 14px;
  color: #8E8E8E;
  padding-left: 10px;
  font-weight: 400;
}

@keyframes animate {
  0% {
    transform: translate(-50%, -70%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -70%) rotate(360deg);
  }
}

/* -----------Plan Container----------- */

.flex-grow {
  flex-grow: 1;
}

.plan-container {
  background-color: #F1F1F1;
  border: 3px solid #FFF;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
}

.plan-container h1 {
  font-family: 'Mitr', sans-serif;
  color: #2977c9 !important;
  font-weight: 400 !important;
}

.plan-container p {
  color: #8E8E8E !important;
  font-family: "Open Sans", sans-serif !important;
}

.plan-container button {
  width: fit-content;
  padding: 10px 30px;
}

.plan-container button.deactive {
  background-color: #DDDDDD;
}

/* -----------Marketplace----------- */

.element .marketplace {
  height: 100vh;
  border-radius: 10px;
  background-color: #FFF;
  padding: 20px;
  max-width: 100%
}

.marketplace p {
  font-family: 'Mitr', sans-serif;
  color: #2977c9;
  font-size: 14px;
}

.explanation-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;
  padding: 20px;
  background-color: #21937B29;
  border-radius: 5px;
  width: 100%;
}

.marketplace .explanation-content {
  color: #818181;
  font-family: "Open Sans", sans-serif !important;
}

.marketplace .explanation-content a {
  color: #157EFB;
}

.marketplace .explanation-btn {
  padding: 10px 20px;
  background-color: #21937B;
  color: #FFF;
  text-decoration: none;
  display: block;
  border-radius: 5px;
  width: fit-content;
}

.marketplace .wrapper {
  width: calc(100% - 330px);
  position: relative;
}

.marketplace .template-wrapper {
  padding: 0px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.marketplace .template-tile {
  min-height: 200px;
  height: 200px;
  /* width: 267px; */
  width: auto;
  min-width: 138px;
  max-width: none;
  background-color: #EFF7FF;
  border: 2px solid #EFF7FF;
  border-radius: 10px;
  display: block;
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
}

.marketplace .template-tile.button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #157EFB;
  border: 1px solid #EFF7FF;
  font-size: 16px;
  font-weight: 600;
  padding: 20px;
  text-align: center;
  flex-direction: column;
  gap: 10px;
  transition: 0.3s;
  cursor: pointer;
  max-width: 200px;
}

.marketplace .template-tile.button:hover {
  border: 1px solid #157EFB;
  transition: 0.3s;
}

.marketplace .template-tile.button i {
  font-size: 21px;
}

.marketplace .template-tile.button p {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #818181;
}

.marketplace .template-tile .preview-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 3;
  padding: 0px 10px;
  height: 30px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  min-height: auto;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  gap: 5px;
}

.marketplace .template-tile .content {
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 0;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  flex-direction: column;
}

.signature-preview {
  position: relative;
  min-height: 190px;
  width: auto;
  height: 200px;
  animation: pan-zoom 12s infinite;
}

.signature-preview img {
  object-fit: cover;
}

@keyframes pan-zoom {
  0% {
    transform: scale(1) translateY(-0%);
  }

  20% {
    transform: scale(3) translateY(-30%);
  }

  40% {
    transform: scale(3) translateY(-30%);
  }

  60% {
    transform: scale(1) translateY(-0%);
  }
}

.signature-preview .user-signature {
  position: absolute;
  bottom: 16%;
  left: 50%;
  transform: translateX(-50%);
  width: 16%;
  height: auto;
  border-radius: 0px;
  padding: 0px;
}

.signature-privacy-btn {
  background-color: #818181 !important;
  color: #FFF !important;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.marketplace .template-tile .content.center {
  justify-content: center;
  align-items: center;
}

.marketplace .template-tile .content.center p {
  color: #818181;
  font-family: "Open Sans", sans-serif;
}

.marketplace .template-tile .content .container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  height: fit-content;
}

.marketplace .template-tile .content button {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #21937B;
  height: fit-content;
  min-height: auto;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.marketplace .template-tile .content .del-btn {
  background-color: #D5415A;
}

.marketplace .template-subevent {
  height: 200px;
  width: 267px;
  max-width: none;
  background-color: #FFF;
  border: 1px solid #F1F1F1;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  flex-direction: column;
  position: relative;
}

.marketplace .template-subevent .subevent {
  background-color: #002D63;
  color: #FFF;
  width: 100%;
  padding: 15px 10px;
  border-radius: 10px 10px 0px 0px;
}

.marketplace .template-subevent .data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-direction: row;
  width: 100%;
  padding: 10px;
}

.marketplace .template-subevent .btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 0px;
}

.marketplace .template-subevent .btn {
  border-radius: 0px;
  text-align: center;
  padding: 5px;
  flex-grow: 1;
  background-color: #157efb29;
  color: #2977c9;
  border: none;
  font-family: 'Mitr', sans-serif;
  font-size: 18px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
}

.marketplace .template-subevent .btn.modified-on {
  background-color: #F1F1F1;
  color: #818181;
  text-align: left;
  padding: 5px 10px;
  border: none;
  align-items: flex-start;
}

.marketplace .template-subevent .btn.modified-on label {
  font-size: 10px;
}

.marketplace .template-subevent .btn.modified-on p {
  font-size: 12px;
  color: #002D63;
}

.marketplace .template-subevent .btn:first-child {
  border-radius: 0px 0px 0px 10px;
}

.marketplace .template-subevent .btn:last-child {
  border-radius: 0px 0px 10px 0px;
}

.marketplace .template-subevent .notification {
  color: #818181;
  font-size: 12px;
}

.marketplace .template-subevent .notification p {
  background-color: #FFF;
  border: 1px solid #D5415A;
  color: #D5415A;
  font-weight: 400;
  font-size: 21px;
  border-radius: 10px;
  padding: 5px 10px;
  text-align: center;
}

.marketplace .template-subevent .fine-text {
  color: #818181;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  max-height: 50px;
  overflow: hidden;
}

.marketplace .email-editor {
  width: 100%;
}

.marketplace .back-btn {
  background-color: transparent;
  border: 1px solid #002D63;
  padding: 5px 20px;
  font-size: 14px;
  color: #002D63;
  transition: 0.3s;
}

.marketplace .back-btn:hover {
  background-color: #002D63;
  color: #FFF;
  transition: 0.3s;
}

.marketplace .email-editor input {
  width: 100%;
}

.marketplace .email-editor textarea {
  min-height: 200px;
  line-height: 26px;
  resize: none;
  overflow: hidden;
}

.marketplace .logo-wrapper {
  padding: 20px;
  width: 100%;
  background-color: #D1EDFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marketplace .logo-wrapper img {
  height: 40px;
  width: auto;
}

.template-tile img {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  object-fit: fill;
  object-position: center;
  border-radius: 10px;
}

.marketplace .sub-nav {
  padding: 20px;
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.marketplace .sub-nav p {
  color: #8E8E8E;
  font-family: "Open Sans", sans-serif;
  line-height: 26px;
}

.marketplace .sub-nav p.verified {
  background-color: #21937B;
  color: #FFF;
  font-weight: 600;
  padding: 3px 10px;
  border-radius: 5px;
  width: fit-content;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.marketplace .sub-nav p.not-verified {
  background-color: #D5415A;
  color: #FFF;
  font-weight: 600;
  padding: 3px 10px;
  border-radius: 5px;
  width: fit-content;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.marketplace .sub-nav p a {
  color: #2977c9;
  font-weight: 600;
  transition: 0.3s;
}

.marketplace h1 {
  color: #000;
  font-size: 15px;
  font-weight: 400;
}

.marketplace h2 {
  color: #000;
  font-size: 40px;
  font-weight: 600;
  vertical-align: middle;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}

.marketplace button {
  width: fit-content;
  padding: 10px 30px;
}

.sub-nav .template-tile {
  background-color: #FFF;
}

.marketplace input[type="file"] {
  color: #000;
}

.marketplace .upload-signature {
  display: flex;
  gap: 0px;
  flex-direction: column;
}

.marketplace .btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.marketplace .btn-wrapper .back-btn {
  padding: 10px 20px;
  border: none;
}

.marketplace .btn-wrapper .back-btn:hover {
  background-color: #F1F1F1;
  color: #002D63;
}

.marketplace .btn-wrapper .use-btn {
  height: auto;
  min-height: auto;
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  background-color: #002D63;
  transition: 0.3s;
}

.marketplace .btn-wrapper .use-btn:hover {
  background-color: #157EFB;
  transition: 0.3s;
}

.marketplace .marketplace-view {
  width: 100%;
  height: 90%;
  object-fit: contain;
  border-radius: 10px;
  background-color: #D1EDFF;
}

/* ------------cropper--------------- */

.slider {
  width: 200px;
  padding: 5px;
}

.cropper-rel {
  width: 100%;
  height: calc(100% - 52px);
  overflow-y: auto;
  position: relative;
}

.cropper-rel .container img {
  border: 3px dashed #157EFB;
}

.cropper-rel button {
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 3;
  background-color: #FFF;
  color: #2977c9;
}

.cropper-zoom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  position: sticky;
  background-color: #FFF;
  bottom: 0;
}

.cropper-zoom .container {
  display: flex;
  flex-direction: column;
  gap: 3px;
  height: fit-content;
}

.cropper-zoom p {
  font-family: "Open Sans", sans-serif;
  color: #8E8E8E;
  text-align: center;
}

.threshold-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: row;
}

.threshold-btns button {
  padding: 8px 20px;
  height: fit-content;
  min-height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.threshold-btns button:hover {
  background-color: #157EFB;
}

.threshold-btns p {
  color: #002D63;
  font-weight: 600;
}

.upload-signature input[type="file"] {
  height: 0;
  width: 0;
  overflow: hidden;
  padding: 0;
  border: none;
}

.upload-signature input[type="file"]+label {
  background: #2977c9;
  border: none;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  padding: 10px 30px;
  text-align: center;
  letter-spacing: normal;
  width: 100%;
}

.discard-btn {
  width: 100% !important;
  background-color: transparent;
  color: #818181;
  padding: 5px 10px !important;
  border: 1px solid #818181 !important;
  border-radius: 5px;
  height: auto !important;
  margin-top: 20px;
  font-size: 14px;
}

.cropper-rel .wrap-1 {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
  margin: 0px;
}

.cropper-rel .wrap-1 h2 {
  font-size: 18px;
  color: #818181;
  font-weight: 400;
  width: 100%;
  justify-content: center;
}

.cropper-rel .wrap-1 .privacy {
  width: 100%;
}

.cropper-rel .wrap-1 .privacy p {
  font-size: 14px;
  color: #818181;
  font-weight: 400;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 21px;
}

.cropper-rel .wrap-1 .privacy b {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 21px;
}

.cropper-rel .wrap-1 .icon {
  width: 100%;
  max-width: 140px;
  border: 2px dashed #157EFB;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.cropper-rel .wrap-1 .icon.sample-signature {
  max-width: 280px;
}

.cropper-rel .wrap-1 h3 {
  color: #D5415A;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 10px;
}

.cropper-rel .wrap-1 .icon p {
  text-align: center;
  padding-top: 10px;
}

.cropper-rel .wrap-1 .icon img {
  width: 100%;
}

/* ------------search--------------- */

.search {
  width: 100%;
  max-width: 400px;
  position: relative;
}

.suggestive-search {
  height: 69px;
  width: 100%;
}

.suggestive-search .search-bg-blur {
  display: none;
  width: 100%;
  height: 100%;
  background-color: #00000069;
  position: fixed;
  z-index: 998;
  top: 0px;
  left: 0px;
}

.suggestive-search.focused .search-bg-blur {
  display: block;
}

.suggestive-search input {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 46px;
}

.suggestive-search button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
}

.suggestive-search.focused input,
.suggestive-search.focused button {
  z-index: 999;
}

.suggestive-search .suggestions {
  position: absolute;
  top: 56px;
  left: 0;
  width: 100%;
  height: fit-content;
  display: none;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  backdrop-filter: blur(5px);
  padding: 10px;
  border: 1px dashed #FFF;
  border-radius: 5px;
}

.suggestive-search.focused .suggestions {
  display: flex;
  z-index: 999;
}

.suggestive-search .suggestions .sug-tile {
  background-color: #E6EBED;
  color: #157EFB;
  font-size: 12px;
  font-weight: 400;
  border-radius: 5px;
  padding: 5px 15px;
  cursor: pointer;
  transition: 0.3s;
}

.suggestive-search .suggestions .sug-tile:hover {
  background-color: #157EFB;
  color: #FFF;
  transition: 0.3s;
}

.search input {
  background-color: #FFF;
  padding: 12px;
  border: none;
}

.search button {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  padding: 8.5px 20px;
  width: fit-content;
  background-color: transparent;
  color: #2977c9;
  transition: 0.3s;
  cursor: pointer;
  border-radius: 0px 10px 10px 0px;
}

.search button:hover {
  background-color: #2977c9;
  color: #FFF;
  transition: 0.3s;
}

.approval-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  flex-grow: 1;
  flex-direction: column;
}

.approval-wrapper .container {
  background-color: #FFFF;
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  transition: 0.3s;
  border: 1px solid #F3F3F3;
}

/* .approval-wrapper .container:hover {
  box-shadow: 0px 0px 15px 9px #E6EBED;
  transition: 0.3s;
  transform: scale(1.006);
} */

.approval-wrapper .next-prev-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.approval-wrapper .next-prev-btns button {
  font-family: "Open Sans", sans-serif;
  padding: 8px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
}

.approval-wrapper .certificate-preview {
  border: 1px solid #157EFB;
  border-radius: 5px;
  height: 73px;
  width: auto;
}

.approval-wrapper .certificate-preview img {
  height: 100%;
  width: auto;
  border-radius: 4px;
  object-fit: cover;
}

.approval-wrapper h2 {
  font-size: 18px;
  font-weight: 600;
  color: #157EFB;
}

.approval-wrapper p {
  font-size: 12px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  color: #818181;
}

.approval-wrapper h3 {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.approval-wrapper h3 .green {
  background-color: #21937B;
  font-weight: 600;
  color: #FFF;
  padding: 3px 10px;
  border-radius: 5px;
  display: inline-block;
}

.approval-wrapper h3 .red {
  background-color: #D5415A;
  font-weight: 600;
  color: #FFF;
  padding: 3px 10px;
  border-radius: 5px;
  display: inline-block;
}

.approval-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #FFF;
  height: 73px;
  width: 73px;
  padding: 10px;
  border: 1px dashed #818181;
  border-radius: 10px;
}

.approval-icon i {
  color: #157EFB;
  font-size: 3rem;
}

.approval-wrapper .actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-direction: column;
}

.approval-wrapper .checkbox {
  position: absolute;
  top: 5px;
  left: 5px;
  width: fit-content;
}

.approval-wrapper .actions .status-btn {
  background-color: #FFF;
  color: #FFF;
  padding: 20px 30px;
  border-radius: 10px;
  border: 4px dashed #FFF;
  font-size: 16px;
  font-weight: 600;
}

.approval-wrapper .actions .status-btn.signed {
  border: 4px dashed #2BCEAD;
  color: #2BCEAD;
}

.approval-wrapper .actions .status-btn.rejected {
  border: 4px dashed #DF7184;
  color: #D5415A;
}

.approval-wrapper .flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.approval-wrapper .flex .cert-count {
  font-size: 14px;
  font-weight: 400;
  color: #157EFB;
  border-radius: 5px;
  background-color: #EFF7FF;
  padding: 5px 10px;
  transition: 0.3s;
}

.approval-wrapper .flex .emails {
  background-color: #EFF7FF;
  color: #157EFB;
  border: 1px solid #157EFB;
  border-radius: 5px;
  padding: 5px 10px;
  transition: 0.3s;
}

.approval-wrapper .flex .emails:hover,
.approval-wrapper .flex .cert-count:hover {
  background-color: #157EFB;
  color: #FFF;
  transition: 0.3s;
}

.approval-wrapper .flex .reject {
  font-size: 14px;
  font-weight: 400;
  color: #D5415A;
  border-radius: 5px;
  background-color: transparent;
  padding: 5px 10px;
  transition: 0.3s;
}

.approval-wrapper .flex .reject:hover {
  background-color: #D5415A;
  color: #FFF;
  transition: 0.3s;
}

.approval-wrapper .flex .sign {
  font-size: 14px;
  font-weight: 400;
  color: #FFF;
  border-radius: 5px;
  background-color: #157EFB;
  padding: 5px 10px;
  flex-grow: 1;
  transition: 0.3s;
}

.approval-wrapper .flex .sign:hover {
  background-color: #2BCEAD;
  transition: 0.3s;
}

.approval-wrapper .group-action {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.approval-wrapper .group-action button:first-child {
  background-color: #F9F9F9;
  color: #D5415A;
}

#centerX,
#centerY {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  display: none;
}

#centerX {
  height: 100%;
  width: 1px;
  border: none;
  background-color: #D5415A;
}

#centerY {
  height: 1px;
  width: 100%;
  border: none;
  background-color: #D5415A;
}

.tags-modal {
  width: 300px;
  height: 500px;
  background-color: #F6F6F6;
  border-radius: 5px;
  z-index: 1000;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;
  padding: 10px;
  cursor: move;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.tags-modal .search-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  flex-direction: row;
}

.tags-modal .search-container input {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #2977c9;
}

.tags-modal .search-container button {
  padding: 3.5px 10px;
  border-radius: 5px;
  height: auto;
  font-size: 18px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #D5415A;
  border: 1px solid #D5415A;
  transition: 0.3s;
}

.tags-modal .search-container button:hover {
  background-color: #D5415A;
  color: #FFF;
  transition: 0.3s;
}

.tags-modal .type-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.tags-modal .type-container button {
  padding: 3px 15px;
  font-size: 14px;
  height: auto;
  border-radius: 0px;
  background-color: #FFF;
  color: #2BCEAD;
}

.tags-modal .type-container button.active {
  background-color: #2BCEAD;
  color: #FFF;
}

.tags-modal .content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  flex-direction: column;
  overflow-y: auto;
  flex-grow: 1;
}

.tags-modal .content::-webkit-scrollbar {
  display: none;
  width: 0px;
}

.tags-modal .content .content-wrapper {
  padding: 10px;
  background-color: #FFF;
  border-radius: 5px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  cursor: default;
  position: relative;
}

.tags-modal .content .content-wrapper .copied-message {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #157EFBCC;
  color: #FFF;
  animation: fade-in-out 0.3s linear;
  animation-iteration-count: 1;
}

@keyframes fade-in-out {
  0% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

.tags-modal .content .tag-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.tags-modal .content .tag-wrapper .tag {
  background-color: #D1EDFF;
  color: #2977c9;
  padding: 2px 10px;
  border-radius: 12px 5px 5px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-direction: row;
}

.tags-modal .content .tag-wrapper .tag .dot {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: #FFF;
}

.tags-modal .content .content-description {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  gap: 10px;
  flex-direction: row;
  flex-wrap: nowrap;
}

.tags-modal .content .content-description button {
  background-color: transparent;
  padding: 0px;
  height: auto;
  cursor: pointer;
}

.tags-modal .content .content-description button.copy {
  color: #818181;
}

.tags-modal .content .content-description button.copy:hover {
  color: #157EFB;
}

.tags-modal .content .content-description button.deletable {
  color: #DF7184;
}

.tags-modal .content .content-description button.deletable:hover {
  color: #D5415A;
}

.tags-modal .content .content-description button.favorite {
  color: #818181;
}

.tags-modal .content .content-description button.favorite.active {
  color: #ffcb74;
}

.tags-modal .content .content-description button.add {
  color: #157EFB;
  right: 10px;
  bottom: 10px;
}

.tags-modal .content .content-description button.add.active {
  color: #2BCEAD;
}

.tags-modal .content .content-description p {
  font-size: 12px;
  color: #8E8E8E;
  flex-grow: 1;
}

.tags-modal .content .save-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
}

.tags-modal .content .save-wrapper p {
  color: #8E8E8E;
  font-size: 12px;
}

.tags-modal .content .save-wrapper p b {
  color: #157EFB;
  font-size: 12px;
  font-weight: 400;
}

.tags-modal .content .save-wrapper textarea,
.tags-modal .content .save-wrapper input {
  resize: none;
  overflow-y: auto;
  font-size: 14px;
}

.tags-modal .content .save-wrapper textarea:nth-child(5) {
  flex-grow: 1;
}

.tags-modal .content .save-wrapper button {
  padding: 5px 15px;
  border-radius: 5px;
  height: auto;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0px auto;
  margin-right: 0px;
}

.tags-modal .content .save-wrapper .tag {
  padding-right: 5px;
}

.tags-modal .content .save-wrapper .delete-btn {
  color: #D5415A;
  cursor: pointer;
}

.tags-modal .content .tag-input-wrapper .popup-message {
  font-size: 12px;
  color: #D5415A;
  position: absolute;
  bottom: 5px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-direction: row;
  background-color: #FFF;
  padding: 0px 5px;
}

.tags-modal .content .tag-input-wrapper {
  position: relative;
  width: 100%;
}

.saved-content-btn i {
  font-size: 18px;
}

/* ---------- Event Modal ----------- */

.event-modal {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;
  width: fit-content;
}

.event-modal h2 {
  font-size: 21px;
  color: #157EFB;
  font-weight: 400;
  padding-bottom: 20px;
}

.event-modal .input-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  max-width: 600px;
  padding-bottom: 10px;
  position: relative;
}

.event-modal .input-wrapper.sub {
  margin-left: 20px;
}

.event-modal .input-wrapper .new {
  padding: 10px;
  width: fit-content;
  min-width: 100px;
  height: 52px;
  font-size: 18px;
  border: 1px solid #D5415A;
  color: #D5415A;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-modal .input-wrapper .all {
  padding: 10px;
  width: fit-content;
  min-width: 200px;
  height: 52px;
  font-size: 18px;
  border: 1px solid #157EFB;
  color: #157EFB;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-modal button {
  width: fit-content;
}

.event-modal .input-wrapper p {
  color: #157EFB;
  font-size: 16px;
}

.event-modal li {
  background-color: #002D63;
  color: #FFF;
  padding: 10px;
  list-style-type: none;
  border-radius: 10px;
}

.event-modal li:hover {
  color: #D1EDFF;
}

.event-modal ul {
  position: absolute;
  top: 62px;
  left: 0;
  width: 100%;
  padding: 0px;
  z-index: 1;
  border-radius: 10px;
  background-color: #002D63;
  max-height: 200px;
  overflow-y: auto;
}

.event-details {
  padding: 3px 10px;
  border: 1px solid #002D63;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 0px;
  flex-direction: column;
  height: 50px;
  position: relative;
  min-width: 150px;
}

.event-details p {
  text-align: right;
  font-size: 0.8rem;
  color: #002D63;
  font-weight: 600;
}

.event-details p:last-child {
  color: #818181;
  font-size: 0.6rem;
}

.event-details label {
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #FFF;
  color: #002D63 !important;
  font-size: 12px !important;
  padding: 0px 3px !important;
}

.tile-wrapper .tile input {
  border: 1px solid #D1EDFF;
}

.tile-wrapper .tile input:focus {
  border: 1px solid #157EFB;
}

.tile-wrapper .tile .flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: row;
}

.tile-wrapper .tile .custom-file-input {
  color: transparent;
  padding: 0px;
  height: 49.31px;
  overflow: hidden;
}

.tile-wrapper .tile .custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.tile-wrapper .tile .custom-file-input::before {
  content: 'Choose PDF';
  color: #FFF;
  display: inline-block;
  background: #157EFB;
  border: none;
  border-radius: 5px;
  padding: 13px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  font-size: 14px;
  height: 49.31px;
  width: 100%;
}

.tile-wrapper .tile .custom-file-input:hover::before {
  border-color: black;
}

.tile-wrapper .tile .custom-file-input:active {
  outline: 0;
}

.tile-wrapper .tile .chosen-doc {
  height: 49.31px;
  padding: 10px;
  border: 1px solid #21937B;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  font-size: 12px;
  flex-direction: row;
  border-radius: 10px;
  color: #818181;
  overflow: hidden;
  min-width: 50%;
}

.tile-wrapper .tile .chosen-doc p {
  max-width: calc(100% - 40px);
  overflow: hidden;
}

.tile-wrapper .tile .chosen-doc button {
  padding: 4px 10px;
  width: fit-content;
  font-size: 14px;
  background-color: #D5415A;
}

.pdf-viewer {
  width: 100%;
  height: 100%;
}

.pdf-full-page {
  width: 100%;
  height: calc(100vh - 100px);
}

.continue-edit {
  background-color: #157EFB;
  color: #FFF;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  padding: 10px 15px;
  font-weight: 600;
  position: absolute;
  bottom: 10px;
  right: 10px;
  opacity: 0.3;
  transition: 0.3s;
}

.template-tile:hover .continue-edit {
  opacity: 1;
  transition: 0.3s;
}

.sub-nav .plan-info {
  font-family: "Open Sans", sans-serif;
}

.settings .view-btn {
  color: #157EFB;
  font-weight: 600;
  text-decoration: none;
}

.settings .view-btn:hover {
  text-decoration: underline;
}

.settings .join-req-tile {
  padding: 10px;
  border-radius: 5px;
  background-color: #FFF;
  width: 100%;
  box-shadow: 0px 0px 9px 4px #E6EBED;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.settings .join-req-tile .action-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.settings .join-req-tile p {
  max-width: calc(100% - 63px);
}

.settings .join-req-tile button {
  padding: 0px;
  border-radius: 50%;
  min-width: none;
  width: 25px;
  height: 25px;
  min-height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #157EFB;
  font-size: 21px;
}

.settings .join-req-tile button i {
  font-size: inherit;
}

.settings .join-req-tile button.accept {
  color: #2BCEAD;
}

.settings .join-req-tile button.reject {
  color: #D5415A;
}

.verification-container {
  padding: 10px;
  border: 1px solid #D5415A;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  flex-direction: column;
  gap: 10px;
}

.verification-container li {
  color: #818181;
}

.organiser-table {
  width: 100%;
}

.organiser-table th {
  background-color: #2977c9;
  color: #FFF;
  padding: 10px;
  font-weight: 400;
}

.organiser-table tr {
  background-color: #F6F6F6;
  color: #818181;
}

.organiser-table tr:nth-child(odd) {
  background-color: #FFF;
}

.organiser-table td {
  padding: 10px;
  text-align: center;
}

.organiser-table tr td:last-child,
.organiser-table tr th:last-child {
  max-width: 80px;
}

.organiser-table .del-btn {
  background-color: #D5415A;
  color: #FFF;
  padding: 0px;
  width: 100%;
  border-radius: 5px;
}

.element-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000069;
  z-index: 998;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.element-modal .preview-img {
  width: 120px;
  height: 120px;
  border: 2px solid #2977c9;
  border-radius: 10px;
  position: relative;
  object-fit: cover;
}

.element-modal .content {
  background-color: #FFF;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;
  min-width: 500px;
  position: relative;
  max-height: 90vh;
}

.element-modal .content .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
}

.element-modal .content .container input {
  border: 1px solid #157EFB;
}

.element-modal .content .container .del-btn {
  background-color: #D5415A;
  padding: 0px;
  width: fit-content;
  height: 30px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  gap: 5px;
  padding: 0px 10px;
}

.transfer-modal .content .container {
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
}

.transfer-modal .content h1,
.recover-modal .content h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #2977C9;
}

.transfer-modal .content p {
  color: #D5415A;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  max-width: 400px;
}

.transfer-modal .content .email-validated {
  color: #21937B;
}

.transfer-modal .content .email-validated i {
  color: inherit;
}

.recover-modal .content p {
  color: #818181;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  max-width: 400px;
}

.recover-modal .content p b {
  color: #157EFB;
  font-weight: 600;
}

.recover-modal .big-icon {
  color: #002D63;
  font-size: 3rem;
}

.element-modal .content .close-btn {
  background-color: #D5415A;
  color: #FFF;
  padding: 0px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 4px solid #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.element-modal .content .close-btn i {
  font-size: inherit;
  color: inherit;
}

.element-modal .share-modal-copy-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background-color: #157efb21;
  border-radius: 5px;
  padding: 15px;
  width: 100%;
}

.share-modal-copy-container p {
  color: #818181;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  max-width: 150px;
}

.share-modal-copy-container button {
  background-color: #FFF;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #157EFB;
  padding: 6px 10px;
  border-radius: 5px;
  transition: 0.3s;
}

.share-modal-copy-container button:hover {
  transition: 0.3s;
  background-color: #157efb29;
}

.template-tile .content.blur {
  background-color: #00000069;
  justify-content: flex-start;
  align-items: flex-start;
  backdrop-filter: blur(2px);
  transition: 0.6s;
  border-radius: 10px;
  opacity: 1;
}

.template-tile .content.blur h2 {
  font-size: 16px;
  color: #FFF;
}

.template-tile .content.blur p {
  color: #D1EDFF;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
}

.template-tile .edit-btn {
  background-color: #FFF;
  color: #157EFB;
  border-radius: 5px;
  padding: 3px 15px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  z-index: 3;
}

.template-tile .content.blur:hover {
  opacity: 0;
  transition: 0.3s;
}

.verified-doc-badge {
  width: fit-content;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #21937B;
  color: #FFF;
  margin-top: 20px;
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000069;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 998;
}

.loading-screen .content {
  background-color: #FFF;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}

.loading-screen .content h2 {
  color: #157EFB;
  font-size: 18px;
  font-weight: 600;
}

.loading-screen .content p {
  color: #D5415A;
}

.loading-screen .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.loading-screen .buttons button:first-child {
  color: #818181;
  background-color: #F1F1F1;
}

.loading-screen .buttons button {
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
  color: #FFF;
  background-color: #157EFB;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #157EFB;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.qr-verify-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  flex-direction: column;
  height: calc(100% - 58px);
  overflow-y: scroll;
}

.qr-verify-container::-webkit-scrollbar {
  width: 0px;
  display: none;
}

.qr-verify-box {
  background-color: #FFF;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  max-width: 400px;
}

.qr-verify-box h2 {
  font-size: 21px;
  color: #157EFB;
  font-weight: 400;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.qr-verify-box p {
  font-size: 14px;
  color: #818181;
}

.qr-verify-box label {
  font-size: 14px;
  color: #002D63;
  font-weight: 600;
  display: block;
  padding-bottom: 3px;
  padding-top: 10px;
  text-transform: capitalize;
}

.qr-verify-box .wrapper {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #157EFB;
  width: 100%;
}

.qr-verify-box .wrapper a {
  color: #157EFB;
  text-decoration: underline;
}

.qr-verify-box .wrapper a i,
.qr-verify-box .wrapper p i {
  color: #21937B;
  padding-right: 5px;
}

.qr-verify-box .wrapper button {
  padding: 5px 20px;
  width: fit-content;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 10px;
}

.qr-verify-box .wrapper.status {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  flex-direction: column;
}

.qr-verify-box .expired,
.qr-verify-box .valid {
  display: inline-block;
  padding: 3px 10px;
  border-radius: 5px;
  color: #FFF;
  background-color: #002D63;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 600;
}

.qr-verify-box .expired {
  background-color: #D5415A;
}

.qr-verify-box .valid {
  background-color: #21937B;
}

.qr-verify-box .verified {
  display: flex;
  gap: 10px;
  align-items: center;
}

.qr-verify-box .verified p {
  font-size: 18px;
  color: #21937B;
  font-weight: 600;
}

.qr-verify-box .verified i {
  font-size: 30px;
  color: #21937B;
}

.qr-verify-box .verified i.fa-spinner {
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

.verification-status-container {
  background-color: #DF718439;
  padding: 20px;
  width: 100%;
  border-radius: 10px;
}

.verification-status-container h2 {
  font-size: 18px;
  color: #002D63;
  font-weight: 600;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.verification-status-container h2 span {
  color: #FFF;
  background-color: #21937B;
  padding: 5px 20px;
  border-radius: 5px;
}

.verification-status-container p {
  color: #818181;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  line-height: 21px;
}

.verification-status-container p b {
  font-weight: 600;
  color: #002D63;
  font-size: 14px;
}

.verification-status-container p.message {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #002D63;
  line-height: 14px;
  width: fit-content;
  margin-top: 10px;
}

.onboarding-tutorial {
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 5px;
  background-color: #21937B;
  color: #FFF;
  z-index: 997;
  width: fit-content;
  height: fit-content;
  max-width: 300px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.onboarding-tutorial .pos-rel {
  padding: 20px;
  position: relative;
}

.onboarding-tutorial-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 14px;
  background-color: #21937B;
  color: #FFF;
  z-index: 99;
  width: 50px;
  height: 50px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
}

.onboarding-tutorial-btn i {
  font-size: inherit;
}

.onboarding-tutorial .buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding-top: 10px;
}

.onboarding-tutorial .close-btn {
  top: 3px;
  right: 3px;
  background-color: transparent;
  color: #CCC;
}

.onboarding-tutorial .close-btn i {
  color: inherit;
  font-size: 16px;
}

.onboarding-tutorial .steps {
  font-size: 12px;
  color: #D1EDFF;
  font-weight: 600;
}

.onboarding-tutorial h2 {
  font-size: 16px;
  font-weight: 600;
}

.onboarding-tutorial p {
  font-size: 14px;
  font-weight: 300;
}

.onboarding-tutorial button {
  padding: 5px 20px;
  height: auto;
  border-radius: 5px;
  background-color: #002D63;
  font-size: 12px;
  display: inline-block;
  width: fit-content;
}

.onboarding-tutorial .caret {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #21937B;
  transform: translate(50%, -100%);
}

.intro-user-data {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #00000069;
  backdrop-filter: blur(3px);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro-user-data .wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 20px;
  max-width: 500px;
  width: 95%;
}

.intro-user-data .wrapper .container {
  padding: 20px;
  width: 120px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #FFF;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  transition: 0.3s;
}

.intro-user-data .wrapper .container.active {
  border: 1px solid #157EFB;
}

.intro-user-data .wrapper .container:hover {
  transition: 0.3s;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.intro-user-data .wrapper .container i {
  font-size: 3rem;
  color: #2b76b9;
}

.intro-user-data .wrapper h2 {
  width: 100%;
  font-weight: 400;
  color: #157EFB;
  font-size: 18px;
}

.intro-user-data .wrapper p {
  width: 100%;
  font-weight: 400;
  color: #818181;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}

.intro-user-data .skip {
  width: 100%;
  font-size: 14px;
  padding: 5px 20px;
  border-radius: 5px;
  background-color: #F6F6F6;
  color: #8E8E8E;
}

.intro-user-data .more-user-info {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-direction: column;
}

.intro-user-data .more-user-info hr {
  border: none;
  background-color: transparent;
  width: 50%;
  height: 1px;
}

.intro-user-data .more-user-info input,
.intro-user-data .more-user-info select {
  width: 100%;
  max-width: 300px;
  border: 1px solid #157EFB;
  font-size: 14px;
  margin-bottom: 10px;
}

.intro-user-data .more-user-info select {
  padding: 12px 13px;
}

.intro-user-data .more-user-info p {
  font-size: 12px;
  font-weight: 600;
  color: #002D63;
  text-align: center;
}

.intro-user-data .more-user-info .error {
  color: #D5415A;
  font-weight: 300;
}

.intro-user-data .more-user-info button {
  font-size: 14px;
  border-radius: 5px;
  transition: 0.3s;
}

.intro-user-data .more-user-info button:hover {
  background-color: #157EFB;
  transition: 0.3s;
}

.feedback-modal {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000021;
}

.feedback-modal .container {
  background-color: #FFF;
  height: fit-content;
  padding: 20px;
  border-radius: 10px;
  gap: 5px;
  width: 95vw;
  max-width: 400px;
}

.feedback-modal .container h2 {
  font-size: 18px;
  font-weight: 400;
  color: #157EFB;
}

.feedback-modal .container p {
  font-size: 14px;
  font-weight: 400;
  color: #818181;
}

.feedback-modal .message-anonymous {
  font-size: 14px !important;
  background-color: #818181;
  color: #FFF !important;
  padding: 5px 10px;
  border-radius: 5px;
  width: fit-content;
}

.feedback-modal .container p span {
  color: #157EFB;
  font-weight: 600;
}

.feedback-modal .button-sec {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.feedback-modal .button-sec button {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  width: fit-content;
  padding: 10px 20px;
}

.feedback-modal .button-sec button.skip {
  background-color: transparent;
  color: #818181;
  font-weight: 400;
}

.feedback-modal textarea {
  border: 2px solid #F1F1F1;
  font-size: 14px;
  height: 120px;
  resize: none;
}

.feedback-modal .warning {
  color: #D5415A !important;
}

.star-rating {
  font-size: 30px;
}

.star-rating .star {
  font-size: inherit;
  cursor: pointer;
}

.star-rating .star:hover {
  color: gold;
}

.star-rating .filled {
  color: gold;
}

.star-rating .active {
  color: gold !important;
}

.star-rating .empty {
  color: lightgray;
}

.feedback-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  flex-wrap: nowrap;
  width: 45%;
  background-color: #F6F6F6;
  padding: 10px;
  border-radius: 10px;
  transition: 0.3s;
  min-width: 250px;
  max-width: 500px;
}

.feedback-wrapper:hover {
  transition: 0.3s;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.feedback-wrapper .container:last-child {
  flex-grow: 1;
}

.feedback-wrapper .container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  width: fit-content;
  word-break: break-all;
}

.feedback-wrapper .date {
  background-color: #8E8E8E;
  color: #FFF;
  border-radius: 5px;
  padding: 3px 10px;
  display: block;
  margin: auto;
  margin-right: 0px;
  margin-top: 0px;
}

.feedback-wrapper .email {
  color: #157EFB;
  font-size: 14px;
  font-weight: 600;
}

.feedback-wrapper hr {
  width: 2px;
  border: none;
  background-color: #e0e0e0;
  height: 100%;
}

.feedback-wrapper .content {
  color: #818181;
  font-size: 14px;
  font-weight: 400;
}

.feedback-wrapper p {
  flex-basis: 100%;
  width: 100%;
}

.star-rating-view {
  color: #ffcb74;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3px;
  width: fit-content;
  height: 25px;
}

.tiles.buysection {
  flex-wrap: wrap !important;
  justify-content: center !important;
}

.buysection .buy-container {
  height: fit-content;
  min-width: 250px;
  width: 100%;
  max-width: 330px;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  border: 1px solid #F1F1F1;
  transition: 0.3s;
  min-height: 398px;
}

.buysection .buy-container:hover,
.buysection .buy-container.active {
  box-shadow: none;
  transition: 0.3s;
}

.buysection .buy-container:hover .inset,
.buysection .buy-container.active .inset {
  scale: 1.03;
  transition: 0.3s;
}

.buy-container .inset {
  padding: 20px;
  transition: 0.3s;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;
  height: 100%;
}

.buy-container button {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
}

.buy-container h3 {
  color: #157EFB;
  font-family: inherit;
  font-size: 18px;
  font-weight: 600;
}

.buy-container .features {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  flex-direction: column;
}

.buy-container hr {
  width: 100%;
  height: 2px;
  border: none;
  background-color: #00000069;
}

.buy-container .features p {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #8E8E8E;
}

.buy-container .current-plan {
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #21937B;
  background-color: #2BCEAD39;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
}

.no-view-copy {
  width: 0px;
  height: 0px;
  overflow: hidden;
  opacity: 0;
}

.copy-container p,
.copy-container h2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.copy-container p b {
  font-weight: 400;
}

.copy-container h2 b {
  font-weight: inherit;
  font-size: inherit;
}

.copy-container p span,
.copy-container h2 span {
  background-color: #c2e9fb;
  color: #157EFB;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copy-container h2 span {
  background-color: #e3e3e3;
  color: #818181;
}

.cert-search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  height: 52px;
}

.cert-search input,
.cert-search select {
  width: fit-content;
  max-width: 400px;
  height: 52px;
}

.cert-search input {
  min-width: 280px;
}

.cert-search .search-btn {
  display: none;
}

.cert-search span {
  color: #157EFB;
  font-size: 14px;
  font-weight: 600;
}

.activity-logs {
  width: 100%;
  color: #818181;
  white-space: pre-line;
  height: 100%;
}

.activity-logs table {
  width: 100%;
}

.activity-logs table th {
  text-align: left;
  color: #157EFB;
  font-size: 14px;
  font-weight: 600;
}

.activity-logs table tr td {
  padding: 10px;
}

.activity-logs table tr:nth-child(2n) {
  background-color: #F3F3F3;
}

.activity-logs table tr td:first-child p {
  font-family: "Open Sans", sans-serif;
  width: fit-content;
  color: #818181;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.activity-logs table tr td:first-child .user-ico {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-transform: uppercase;
}

.activity-logs table tr td p {
  font-family: "Open Sans", sans-serif;
  width: fit-content;
  color: #818181;
  font-weight: 600;
  font-size: 14px;
}

.activity-logs table tr td:last-child p {
  font-family: "Open Sans", sans-serif;
  width: fit-content;
  color: #818181;
  font-weight: 600;
  font-size: 14px;
}

.activity-logs .selector {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 20px 0px;
  position: sticky;
  top: 0px;
  left: 0px;
  background-color: #FFF;
}

.activity-logs .selector button {
  font-family: "Open Sans", sans-serif;
  width: fit-content;
  color: #FFF;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.activity-logs .selector button p {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.activity-logs .selector select {
  width: fit-content;
  font-family: "Open Sans", sans-serif;
  color: #157EFB;
  font-weight: 600;
  font-size: 14px;
  border: 0px;
  padding: 3px 10px;
  background-color: #F6F6F6;
  border-radius: 5px;
}

.activity-logs .selector select:focus {
  border: 0px;
}

.activity-logs .selector p {
  width: fit-content;
  font-family: "Open Sans", sans-serif;
  width: fit-content;
  color: #818181;
  font-weight: 600;
  font-size: 14px;
}

.overflow-table-scroll {
  width: 100%;
  height: calc(100% - 75px);
  overflow: scroll;
}

.no-padding-top {
  padding-top: 0px !important;
}

.document-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap;
  flex-direction: row;
  position: relative;
}

.document-header .sort-filter {
  background-color: #FFF;
  padding: 10px 20px;
  border-radius: 5px;
  height: 100%;
  min-width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.document-header .dropdown-filter {
  position: absolute;
  top: 47px;
  right: 0;
  padding: 5px;
  background-color: #FFF;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  width: 140px;
  z-index: 99;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0px;
  border-radius: 5px;
  flex-direction: column;
}

.document-header .dropdown-filter p {
  font-size: 12px;
  font-weight: 600;
  color: #818181;
  padding: 5px 10px;
  width: 100%;
  transition: 0.3s;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.document-header .dropdown-filter p:hover {
  background-color: #00000011;
  transition: 0.3s;
}

.popup-storagewarn {
  width: 100%;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #cfe2de;
  color: #000;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.popup-storagewarn p {
  color: #818181;
  font-size: 12px;
  font-weight: 600;
}

.popup-storagewarn p span {
  color: #21937B;
  font-size: 16px;
}

.popup-storagewarn a {
  text-decoration: none;
  background-color: #FFF;
  color: #21937B;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  display: block;
}

.popup-storagewarn.above90 {
  background-color: #D5415A;
  color: #FFF;
}

.popup-storagewarn.above70 {
  background-color: #ff980069;
}

.popup-storagewarn .storage-progress {
  min-width: 200px;
  height: 10px;
  border-radius: 5px;
  background-color: #F1F1F1;
  overflow: hidden;
}

.popup-storagewarn .storage-progress .bar {
  height: 100%;
  background-color: #21937B;
}

.gdrive-video-1 {
  max-width: 100%;
  min-width: 80%;
  aspect-ratio: 3 / 2;
  height: auto;
  border: none;
  border-radius: 10px;
  display: block;
  margin: auto;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.tutorial-opacity {
  opacity: 0.4;
  transition: 0.3s;
}

.tutorial-opacity:hover {
  opacity: 1;
  transition: 0.3s;
}

.warn-red-color {
  color: #D5415A !important;
}

.empty-message {
  padding: 10px;
  border-radius: 5px;
  width: fit-content;
  background-color: transparent;
  color: #002D63;
  width: 100%;
  height: 100%;
  display: flex;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.empty-message i {
  font-size: 3rem;
  color: #2977C9;
}

.social-share-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000069;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  flex-direction: column;
  gap: 10px;
}

.social-share-modal .container,
.social-share-modal .wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-direction: column;
  background-color: #FFF;
  padding: 20px;
  border-radius: 5px;
  min-width: 280px;
  max-width: 400px;
  width: 90%;
}

.social-share-modal .wrapper {
  padding: 0px;
  align-items: flex-end;
  background-color: transparent;
}

.social-share-modal .container button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 10px;
  align-items: center;
  flex-direction: row;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
}

.social-share-modal .container h1 {
  text-align: left;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #002D63;
  width: 100%;
}

.social-share-modal .close-btn {
  position: relative;
  top: auto;
  right: auto;
  transform: translate(0%, 0%);
  width: fit-content;
  border-radius: 5px;
  padding: 5px;
  color: #D5415A;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  gap: 10px;
  border: 1px solid #FFF;
  height: 35px;
}

.social-share-modal .close-btn:hover {
  border: 1px solid #D5415A;
  background-color: #FFF;
}

.social-share-modal .container button:hover {
  padding-right: 40px;
}

.social-share-modal .container button:nth-child(3) {
  background-color: #16B6E8;
}

.social-share-modal .container button:nth-child(4) {
  background-color: #1FD5B0;
}

.social-share-modal .container button:nth-child(5) {
  background-color: #FFF;
  color: #818181;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.social-share-modal .container button i {
  font-size: 21px;
}

@media screen and (max-width: 1620px) {
  .element .fadeout {
    display: none;
  }

  /* .marketplace .sub-nav {
    display: none;
  } */

  .element .settings .sub-nav {
    height: fit-content;
    /* width: 100%; */
    width: fit-content;
    flex-grow: 1;
  }

  .profile-progress {
    display: none;
  }

  .sub-nav .profile-storage {
    position: relative;
  }
}

@media screen and (max-width: 1250px) {
  .element {
    max-width: 100%;
  }
}

@media screen and (max-width: 820px) {
  .onboarding-tutorial {
    display: none;
  }

  .onboarding-tutorial-btn {
    display: none;
  }

  .complete-profile {
    width: 100%;
    box-shadow: none;
    margin: 0px;
    padding: 0px;
  }

  .complete-profile .circle-wrap {
    display: none;
  }

  .marketplace .template-wrapper {
    width: 100%;
  }

  .marketplace .template-tile {
    width: fit-content;
    min-width: 276px;
  }

  .element {
    max-width: 100%;
    height: 100%;
  }

  .element .tiles {
    min-width: 300px;
    width: 100%;
  }

  .element .tiles::-webkit-scrollbar {
    display: none;
  }

  .element-modal .content {
    min-width: auto;
    max-width: 90%;
  }

  .tile-wrapper {
    width: 100%;
    height: fit-content;
    align-items: center;
  }

  .element .tiles .tile {
    width: 100%;
    max-width: 400px;
    height: 240px;
  }

  .tile-wrapper .action {
    display: none;
  }

  .element .container .header .toggle button {
    font-size: 15px;
    border-radius: 5px;
  }

  .element .container .header .toggle {
    width: 100%;
    justify-content: space-between;
  }

  .btn-issue {
    display: none;
  }

  .marketplace .sub-nav {
    display: none;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
  }

  .marketplace .wrapper::-webkit-scrollbar {
    display: none;
  }

  .marketplace .wrapper {
    width: 100%;
    padding: 0px;
  }

  .element .tiles.marketplace {
    padding: 10px;
    /* height: calc(100% - 70px); */
    height: 100vh;
  }

  .upload-doc-btn {
    text-decoration: none;
    color: #157EFB;
    padding: 10px;
    min-width: 80%;
    text-align: center;
    border-radius: 10px;
    border: 1px solid #157EFB;
    justify-content: center;
    align-items: center;
  }

  .element .settings .sub-nav {
    height: fit-content;
  }

  .sub-nav .profile-storage {
    position: relative;
  }

  .profile-progress {
    display: none;
  }

  .settings .wrapper {
    height: fit-content;
  }

  .marketplace .sub-nav.show {
    display: flex;
  }

  .marketplace .sub-nav.hide {
    display: none;
  }

  .feedback-wrapper {
    width: 100%;
    flex-wrap: wrap;
  }

  .feedback-wrapper hr {
    display: none;
  }

  .feedback-wrapper .date {
    margin: 0px;
  }

  .activity-logs .selector button p {
    display: none;
  }

  .settings-container .apply-btn {
    position: fixed;
    width: fit-content;
    bottom: 70px;
    right: 10px;
  }

  .settings-container .apply-btn button:hover {
    width: 60px;
    border-radius: 50%;
  }

  .settings-container .apply-btn button:hover p {
    display: none;
  }

  .cert-search {
    display: none;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 997;
    background-color: #00000069;
    padding: 10px;
  }

  .cert-search input,
  .cert-search select {
    width: 100%;
  }

  .cert-search span {
    display: none;
  }

  .cert-search .search-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    width: fit-content;
    height: 52px;
    width: 52px;
    background-color: #c2e9fb;
    color: #157EFB;
  }

  .cert-search .search-btn i {
    pointer-events: none;
  }

  .cert-search.show {
    display: flex;
  }

  .cert-search.hide {
    display: none;
  }

  .qr-verify-container {
    justify-content: flex-start;
  }
}
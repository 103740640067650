.header {
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.header .logo {
  height: 38px;
  width: auto;
  object-fit: contain;
  padding: 0px;
  border-radius: 0px;
}

.signout {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  background-color: transparent;
  color: #DF7184;
  border: 1px solid #DF7184;
  transition: 0.3s;
  font-weight: 600;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
}

.signout:hover {
  background-color: #DF7184;
  color: #FFF;
  border: 1px solid #DF7184;
  transition: 0.3s;
}

.signin-btn {
  width: fit-content;
  background-color: transparent;
  color: #2977c9;
  border: 2px solid #2977c9;
  transition: 0.3s;
  padding: 3px 30px;
}

.signin-btn:hover {
  width: fit-content;
  background-color: #2977c9;
  color: #FFF;
  border: 2px solid #2977c9;
  transition: 0.3s;
}

.header .container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.header .container-left {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
}

.profile-icon {
  height: 39px;
  width: 39px;
  /* border: 1px solid #626262; */
  border-radius: 50%;
  object-fit: cover;
  transition: 0.3s;
}

:root {
  --random-color: #000000;
  /* Default to black */
}

p.profile-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  font-weight: 600;
  background-color: var(--random-color);
  color: #FFF;
}

.profile-icon:hover {
  transition: 0.3s;
  box-shadow: #2977c999 0px 0px 12px;
}

.profile-icon-container {
  position: relative;
  height: 39px;
  width: 39px;
}

.profile-icon-container .user-nav {
  position: absolute;
  min-width: 280px;
  max-width: 360px;
  width: 95vw;
  top: 50px;
  right: 0px;
  padding: 10px;
  border-radius: 10px;
  background-color: #FFF;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.user-nav .blocks-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.user-nav .blocks {
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  font-size: 12px;
  text-decoration: none;
  font-weight: 600;
  color: #2977c9;
  border-radius: 8px;
}

.user-nav .blocks.coming-soon {
  color: #CCC;
}

.user-nav .blocks.coming-soon:hover {
  background-color: transparent;
}

.user-nav .privacy {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #F6F6F6;
  padding: 10px;
  width: 100%;
  border-radius: 8px;
}

.user-nav .privacy a {
  font-size: 12px;
  font-weight: 600;
  color: #626262;
  text-decoration: none;
}

.user-nav .privacy .dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #626262;
}

.user-nav .blocks i {
  font-size: 1.6rem;
}

.user-nav .blocks:hover {
  background-color: #2977c919;
}

.user-nav .container {
  background-color: #F6F6F6;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;
}

.user-nav .icon {
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #2977c9;
}

.user-nav .icon i {
  color: inherit;
}

.user-nav .content {
  width: calc(100% - 50px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0px;
  flex-direction: column;
}

.user-nav h2 {
  font-size: 12px;
  color: #626262;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.user-nav h3 {
  font-size: 14px;
  color: #626262;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.user-nav .container a {
  font-size: 12px;
  background-color: transparent;
  color: #2977c9;
  border: 1px solid #2977c9;
  font-weight: 600;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
}

.user-nav .container a:hover {
  background-color: #00000009;
}

.header .home {
  width: fit-content;
  background-color: transparent;
  color: #2977c9;
  border: 2px solid transparent;
  transition: 0.3s;
  padding: 3px 10px;
  text-decoration: none;
  font-size: 18px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.header .help {
  width: fit-content;
  background-color: transparent;
  color: #626262;
  border: 1px solid #626262;
  transition: 0.3s;
  padding: 4px 10px;
  text-decoration: none;
  font-size: 18px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.header .help i {
  font-size: 16px;
}

.header .home:hover {
  width: fit-content;
  background-color: #2977c9;
  color: #FFF;
  border-color: #2977c9;
  transition: 0.3s;
}

.header .help:hover {
  background-color: #00000019;
}

.header .container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.header .container-left {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
}

.header .container-right {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
}

.header .download-btn {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-direction: row;
}

.header .download-btn p {
  font-weight: 600;
}

.header .download-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  background-color: #FFF;
  border-radius: 10px;
  padding: 7px 12px;
}

.more-action-btn {
  display: none;
}

@media screen and (max-width: 1200px) {}

@media screen and (max-width: 820px) {

  .header .container .help,
  .header .container .home,
  .header .container .signout {
    display: none;
  }

  .header .container-left {
    width: 100%;
    justify-content: space-between;
  }

  .more-action-btn {
    display: block;
    width: fit-content;
    padding: 0px;
    background-color: transparent;
    color: #000;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .more-action-btn i {
    font-size: 21px;
    color: inherit;
  }
}
*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 26px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}

.element {
  flex-grow: 1;
  max-height: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.element .content-tile {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 20px;
  max-height: calc(100vh - 210px);
  overflow-y: scroll;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  height: 100vh;
  max-width: 100%;
  padding: 20px;
  flex-direction: row;
  flex-wrap: wrap;
}

.element .container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.element .container .header h1 label {
  color: #2977c9;
  color: #8e8e8e;
  display: block;
  font-family: Mitr, sans-serif;
  font-size: 14px;
  font-weight: 300;
  text-transform: capitalize;
}

.element .container .header h1 {
  color: #2977c9;
  font-family: Mitr, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 20px;
}

.element .logs-btn {
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
}

.element .logs-table,
.element .logs-table table {
  width: 100%;
  position: relative;
}

.element .logs-table {
  height: calc(100% - 77px);
}

.element .logs-table th {
  background-color: #8e8e8e;
  color: #FFF;
  padding: 10px;
  font-weight: 400;
}

.element .logs-table td {
  padding: 10px;
}

.element .logs-table td.verified {
  background-color: #2BCEAD;
  color: #FFF;
  text-align: center;
  font-weight: 600;
}

.element .logs-table td.rejected {
  background-color: #D5415A;
  color: #FFF;
  text-align: center;
  font-weight: 600;
}

.element .logs-table td button {
  padding: 5px 10px;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  width: fit-content;
  display: block;
  margin: auto;
}

.element .logs-table tr:nth-child(2n) {
  background-color: #F1F1F1;
}

.modal {
  width: 100%;
  height: 100%;
  background-color: #00000069;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.modal .content {
  padding: 20px;
  border-radius: 5px;
  background-color: #FFF;
  min-width: 200px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.modal .content.promo {
  padding: 20px 6%;
}

.modal .content h2 {
  font-size: 16px;
  font-weight: 600;
  color: #2977c9;
}

.modal input {
  border: 2px solid #F1F1F1;
  max-width: 300px;
}

.modal input.promo_code {
  text-transform: uppercase;
}

.action-container-1 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  flex-direction: column;
  padding: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  width: 280px;
}

.action-container-1 .container {
  gap: 5px !important;
  width: 100%;
}

.action-container-1 label {
  color: #2977c9;
  font-size: 14px;
  font-weight: 600;
}

.action-container-1 h2 {
  color: #818181;
  font-size: 16px;
  font-weight: 400;
}

.action-container-1 p {
  color: #818181;
  font-size: 14px;
  font-weight: 400;
}

.action-container-1 h3 {
  color: #D49A39;
  font-size: 16px;
  font-weight: 600;
}

.action-container-1 button {
  width: 100%;
  border-radius: 0px;
  border-radius: 5px;
  background-color: #2BCEAD;
}

.action-container-1 button:last-child {
  background-color: #DF7184;
}

.file-block {
  background-color: #2977c9 !important;
  padding: 10px;
  font-size: 14px;
  color: #FFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
}

.file-block button {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.action-container-1 select {
  border: 1px solid #2977c9;
}

.action-container-1 textarea {
  border: 1px solid #2977c9;
  height: 150px;
}

.file-block a {
  color: inherit;
  text-decoration: none;
}
.sidenav,
.ads-sidenav {
    padding: 30px;
    border-radius: 10px;
    background-color: #FFF;
    max-width: 400px;
    width: 100%;
    overflow-y: hidden;
    position: relative;
    height: fit-content;
}

.sidenav .scroll {
    height: calc(100vh - 280px);
    overflow-y: scroll;
    color: rgba(0, 0, 0, 0);
    transition: color .3s ease;
}

.copyright {
    color: #000;
}

.sidenav .scroll:hover {
    color: rgba(0, 0, 0, 0.3);
}

.sidenav .container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    flex-direction: column;
    width: 100%;
}

.sidenav .container .profile {
    width: 100%;
    display: flex;
    gap: 30px;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
}

.sidenav .container .profile .user-sec {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
}

.org-sec {
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
    justify-content: space-between;
    align-items: center;
    background-color: #FFF;
    flex-grow: 1;
    padding: 4px 20px;
    padding-right: 63px;
    border: 1px solid #2977c9;
    border-radius: 10px;
    width: 100%;
    position: relative;
}

.org-sec p {
    color: #8E8E8E;
}

.org-sec h1 {
    font-family: "Open Sans", sans-serif !important;
    font-size: 25px !important;
    font-weight: 600 !important;
    flex-direction: row !important;
    align-items: center;
}

.org-sec .org-name {
    position: absolute;
    top: -20px;
    left: 0px;
    background-color: #FFF;
    color: #2977c9;
    padding: 0px;
    font-size: 12px;
    font-weight: 600;
}

.org-sec .org-plan {
    background-color: #FFF;
    border: 1px solid #FFC76A;
    padding: 2px 10px;
    color: #D49A39;
    font-size: 12px;
    border-radius: 10px;
    text-decoration: none;
}

.org-sec .org-plan.premium {
    background-image: linear-gradient(20deg, #FFC76A, #D49A39);
    color: #FFF;
    font-weight: 600;
}

.org-sec .add-credits {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #2977c9;
    color: #FFF;
    padding: 10px;
    font-size: 12px;
    font-weight: 600;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 8px 8px 0px;
}

.org-sec .add-credits {
    text-decoration: none;
}

.org-sec .add-credits p {
    width: 0px;
    overflow-x: hidden;
    color: #FFF;
    padding: 0px;
    text-align: center;
    transition: 0.3s;
}

.org-sec .add-credits:hover p {
    width: 120px;
    transition: 0.3s;
}

.org-sec .add-credits:hover {
    border-radius: 8px;
}

.sidenav .container,
.sidenav .container .profile {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
}

.sidenav .container img {
    width: 100px;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.sidenav .container h1 {
    display: flex;
    font-size: 18px;
    gap: 10px;
    flex-direction: column;
    font-family: 'Mitr', sans-serif;
    font-weight: 400;
}

.sidenav .container h1 .flex-fs b {
    font-size: 18px;
    font-weight: 900;
}

.sidenav .search {
    width: 100%;
    position: relative;
}

.sidenav .search input {
    background-color: #F1F1F1;
    padding: 12px;
}

.sidenav .search button {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    padding: 8.5px 20px;
    width: fit-content;
    background-color: transparent;
    color: #2977c9;
    transition: 0.3s;
    cursor: pointer;
}

.sidenav .search button:hover {
    background-color: #2977c9;
    color: #FFF;
    transition: 0.3s;
}

.sidenav .module {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.sidenav .module label {
    font-family: 'Mitr', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #8E8E8E;
}

.sidenav .module .nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    text-decoration: none;
    color: #000;
    font-size: 16px;
    position: relative;
}

.sidenav .module .nav:hover {
    background-image: linear-gradient(to right, #F1F1F1 10%, #FFF 90%);
    border-radius: 10px;
}

.sidenav .module .selected i {
    background-color: #2977c9 !important;
    color: #FFF !important;
}

.sidenav .module a,
.sidenav .module p {
    width: 100%;
    display: block;
    transition: 0.3s;
    border-radius: 10px;
}

.sidenav .module a i,
.sidenav .module p i {
    pointer-events: none;
}

.sidenav .module a:hover i,
.sidenav .module p:hover i {
    background-color: #2977c9;
    color: #FFF;
    transition: 0.3s;
}

.sidenav .module a.active,
.sidenav .module p.active {
    background-color: #2977c9;
    color: #FFF;
}

.sidenav .module a.active i,
.sidenav .module p.active i {
    background-color: #DF7184;
    color: #FFF;
}


.sidenav .module .nav i {
    color: #2977c9;
    background-color: #F1F1F1;
    border-radius: 10px;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
}

.sidenav .module .nav .notification-bell,
.sidenav .module .nav .notification-bell:hover {
    background-color: transparent !important;
    color: #DF7184 !important;
}

.sidenav .userbadge {
    padding: 0px;
    border: none;
    background-color: #dfdfdf;
    color: #FFF;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    text-transform: uppercase;
}

.sidenav .userbadge i {
    font-size: inherit;
}

.sidenav .userbadge.superadmin {
    color: #FFF;
    background-color: #DF7184;
    font-weight: 600;
    border: none;
}

.sidenav .storage {
    gap: 10px;
}

.sidenav .storage .buy-storage {
    background-color: #2BCEAD;
    color: #FFF;
}

.sidenav .storage .policy {
    font-size: 12px;
    color: #8E8E8E;
    text-decoration: none;
}

.sidenav .progress-bar {
    width: 100%;
    height: 15px;
    background-color: #F1F1F1;
    border-radius: 10px;
}

.sidenav .progress-bar .value {
    background-color: #2977c9;
    border-radius: 10px;
    height: 15px;
    width: 30%;
}

.btn-yellow {
    background-color: #FFC76A;
    color: #000;
}

.coin-icon {
    height: 24px !important;
    width: 24px !important;
}

.sidenav .mobile {
    display: none;
}

.sidenav .min-sidenav-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
    padding: 30px 10px;
    background-color: #2977c9;
    color: #FFF;
    border-radius: 10px 0px 0px 10px;
    transition: 0.2s;
}

.sidenav .min-sidenav-btn:hover {
    padding-left: 15px;
    transition: 0.2s;
}

.min-sidenav {
    width: fit-content !important;
    overflow-y: unset;
}

.min-sidenav .fg-1 {
    display: none;
}

.min-sidenav .notification-bell {
    position: absolute;
    top: -20px;
    right: -14px;
}

.min-sidenav .scroll::-webkit-scrollbar {
    width: 0px;
}

.min-sidenav .module a,
.min-sidenav .module p {
    width: fit-content;
}

.min-sidenav .min-sidenav-btn {
    background-color: transparent;
    color: #2977c9;
    padding-left: 5px;
    padding-right: 5px;
}

.min-sidenav .min-sidenav-btn:hover {
    background-color: #2977c9;
    padding-left: 5px;
    color: #FFF;
}

.min-sidenav .container {
    width: fit-content;
}

@media screen and (max-width: 1250px) {
    .sidenav {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 999;
        box-shadow: 0px 0px 20px 0px #00000069;
        border-radius: 0px;
        height: 100vh;
    }

    .main {
        margin-left: 75px;
    }

    .issue-page {
        margin-left: 0px;
    }

    .sidenav .scroll {
        height: calc(100vh - 200px);
    }

    .ads-sidenav {
        display: none;
    }
}

@media screen and (max-width: 820px) {

    .main {
        margin-left: 0px;
    }

    .min-sidenav {
        width: 100% !important;
    }

    .min-sidenav-btn {
        display: none !important;
    }

    .sidenav .container {
        background-color: #00000069;
        backdrop-filter: blur(3px);
        position: fixed;
        bottom: 60px;
        left: 0px;
        height: calc(100% - 60px);
        width: 100%;
        padding: 10px;
        overflow: hidden;
        display: flex;
        justify-content: flex-end;
    }

    #mobile-nav-modal {
        display: none;
    }

    .sidenav .container .profile {
        display: none;
    }

    .sidenav .container .copyright {
        display: none;
    }

    .sidenav .container .container {
        height: fit-content;
        max-height: 100%;
        position: relative;
        bottom: auto;
        left: auto;
        overflow: scroll;
        background-color: #FFF;
        border-radius: 10px;
        padding: 10px 20px;
        display: flex;
        justify-content: flex-start;
    }

    .sidenav .mobile {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 10px;
        flex-direction: row;
        width: 100%;
    }

    .sidenav {
        position: fixed;
        bottom: 0;
        left: 0;
        border-radius: 0px;
        background-color: #2977c9;
        color: #FFF;
        z-index: 998;
        height: 60px;
        display: flex;
        padding: 10px;
        width: 100%;
        max-width: none;
    }

    .sidenav .mobile .nav-ico {
        color: #F6F6F6;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1px;
        flex-direction: column;
        text-decoration: none;
        padding: 5px;
        border-radius: 5px;
        flex-grow: 1;
    }

    .sidenav .mobile .nav-ico.active {
        background-color: #D0E5FE;
        color: #2977c9;
    }

    .sidenav .mobile .nav-ico i {
        color: inherit;
        font-size: 18px;
    }

    .sidenav .mobile .nav-ico p {
        font-size: inherit;
    }
}
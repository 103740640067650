.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    color: #000;
}

.loading h1 {
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 15px;
}

.loading P {
    font-size: 16px;
    color: #2977C9;
}

.warning-banner {
    position: fixed;
    bottom: -80px;
    left: 50%;
    transform: translateX(-50%);
    min-height: 80px;
    width: 80px;
    max-width: 600px;
    border-radius: 40px;
    box-shadow: none;
    background-color: #FFCB74;
    color: #000;
    z-index: 999;

    animation-name: warningBanner;
    animation-duration: 1.6s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    padding: 10px 30px;
    gap: 10px;
}

.success {
    background-color: #2BCEAD;
}

.warning-banner .relative {
    position: relative;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}

.warning-banner .close-btn {
    background-color: #DF7184;
    color: #FFF;
    font-size: 10px;

    position: relative;
    top: auto;
    left: auto;
    right: auto;

    border-radius: 10px;
    height: 30px;
    width: 30px;

    padding: 10px;
}

.warning-banner .close-btn i {
    color: inherit;
    font-size: inherit;
}

.warning-action {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: #00000069;
    font-family: "Open Sans", sans-serif !important;
}

.warning-action .relative {
    width: 100%;
    height: 100%;
    font-family: inherit;
}

.warning-action .close-btn {
    width: 30px;
    height: 30px;
    position: absolute;
    top: -36px;
    right: 0px;
    font-size: 14px;
    color: #DF7184;
    border-radius: 10px;
}

.warning-action .close-btn i {
    font-size: inherit;
    color: inherit;
}

.warning-action .close-btn:hover {
    background-color: #DF7184;
    color: #FFF;
}

.warning-action .content {
    background-color: #FFF;
    padding: 20px;
    border-radius: 10px;
    width: 95%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 25vw;
    max-width: 360px;
    font-family: inherit;
}

.warning-action .content p {
    font-size: 18px;
    font-weight: 400;
    color: #2977C9;
    font-family: inherit;
}

.warning-action .content p.subtext {
    font-size: 14px;
    font-weight: 400;
    color: #888;
    padding-top: 5px;
    font-family: inherit;
}

.warning-action .button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    font-family: inherit;
}

.warning-action .button-container .button {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    margin: 0;
    font-size: 14px;
    padding: 10px 20px;
    background-color: #F1F1F1;
    border-radius: 10px;
    color: #888;
    text-decoration: none;
    height: auto;
    width: fit-content;
}

.warning-action .proceed-btn {
    background-color: #DF7184 !important;
    color: #FFF !important;
}

.warning-action.success .proceed-btn {
    background-color: #2BCEAD !important;
    color: #FFF !important;
}

@keyframes warningBanner {
    0% {
        width: 80px;
        bottom: -80px;
        border-radius: 40px;
        height: 120px;
        box-shadow: none;
    }

    20% {
        width: 50px;
        bottom: 15px;
        height: 80px;
    }

    100% {
        width: 80vw;
        bottom: 50px;
        border-radius: 10px;
        height: fit-content;
        box-shadow: 0px 0px 20px #00000021;
    }
}

.warning-banner a {
    text-decoration: underline !important;
    color: #2977C9 !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
}

.warning-banner a:hover {
    color: #000 !important;
    transition: 0.3s !important;
}

.warning-banner p,
.warning-banner .close-btn,
.warning-banner a {
    opacity: 0;
    animation-name: opacityActive;
    animation-delay: 0.9s;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    -webkit-animation-name: opacityActive;
    -webkit-animation-delay: 0.9s;
    -webkit-animation-duration: 0.3s;
    -webkit-animation-fill-mode: forwards;
}

.warning-banner p {
    color: #000 !important;
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 400 !important;
}

@keyframes opacityActive {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.progress-loader {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000019;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0px 10px;
}

.progress-loader .container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
    flex-direction: column !important;
    background-color: #333333F9 !important;
    padding: 20px !important;
    border-radius: 10px 10px 0px 0px !important;
    height: fit-content !important;
}

.progress-loader .tips {
    color: #FFF !important;
}

.progress-loader p {
    color: #888 !important;
    width: 100% !important;
    font-weight: 600 !important;
    text-align: left !important;
    font-size: 12px !important;
}

.progress-loader .loader-p {
    max-width: 500px;
    width: calc(90vw - 20px);
    margin: 0 auto;
    border-radius: 10px;
    border: 4px solid transparent;
    position: relative;
    padding: 1px;
}

.progress-loader .loader-p:before {
    content: '';
    border: 1px solid #fff;
    border-radius: 10px;
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
}

.progress-loader .loader-p .loaderBar {
    position: absolute;
    border-radius: 10px;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    background: #fff;
    width: 0;
    animation: borealisBar 2s linear infinite;
}

@keyframes borealisBar {
    0% {
        left: 0%;
        right: 100%;
        width: 0%;
    }

    10% {
        left: 0%;
        right: 75%;
        width: 25%;
    }

    90% {
        right: 0%;
        left: 75%;
        width: 25%;
    }

    100% {
        left: 100%;
        right: 0%;
        width: 0%;
    }
}